import { AxiosPromise } from 'axios';
import PasswordChangeInfo from '@/model/user/PasswordChangeInfo';
import User from '@/model/user/User';
import UserUpdateItemInfo from '@/model/user/UserUpdateItemInfo';
import UserInfo from '@/model/user/UserInfo';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import axios from '../AxiosBase';

export default {
  /**
   * ユーザー情報全件取得
   */
  findAll():AxiosPromise<any> {
    return axios.get('user/all');
  },

  /*
  * ユーザー情報取得
  */
  findByID():AxiosPromise<any> {
    return axios.get('user/byid');
  },
  /*
  * ユーザー情報取得
  */
  privfindByID(userid:any):AxiosPromise<UserInfo> {
    return axios.get(`admin/user/byprivid/${userid}`);
  },

  /**
   * ユーザー情報更新
   */
  update(userinfo:UserInfo):AxiosPromise<any> {
    return axios.post('user/register', userinfo);
  },

  /**
   * ユーザー情報更新（管理者用）
   */
  privUpdate(userinfo:UserInfo):AxiosPromise<any> {
    return axios.post('admin/user/register', userinfo);
  },

  /**
   * パスワード再設定
   */
  passwordChange(passwordInfo: PasswordChangeInfo):AxiosPromise<any> {
    return axios.post('user/passwordChange', passwordInfo);
  },

  getLoginUser():AxiosPromise<UserBaseInfo> {
    return axios.get('user/getLoginUser');
  },

  getURL(photo:string):AxiosPromise<string> {
    return axios.get(`user/getURL/${photo}`);
  },

  /**
   * 閲覧者一覧取得
   */
  getViewers():AxiosPromise<User[]> {
    return axios.get('admin/user/findViewers');
  },

  saveUpdateItem(userUpdateItemList:UserUpdateItemInfo[]):AxiosPromise<UserUpdateItemInfo[]> {
    return axios.post('user/saveUpdateItem', userUpdateItemList);
  },

  privfindUpdateItem(userId:string):AxiosPromise<any> {
    return axios.get(`admin/user/findUpdateItem/${userId}`);
  },

  findUpdateItem():AxiosPromise<any> {
    return axios.get('user/findUpdateItem');
  }
};
