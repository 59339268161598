export default class Size {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: Size): Size {
      return new Size(source.value);
    }
}
