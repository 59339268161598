export default class TypeJpEra {
  era: string;
  jpEra: string;
  min: Date;
  max: Date;
  maxYears: number;
  constructor(
    era: string,
    jpEra: string,
    min: Date,
    max: Date,
    maxYears: number,
  ) {
    this.era = era;
    this.jpEra = jpEra;
    this.min = min;
    this.max = max;
    this.maxYears = maxYears;
  }
}
export const Meiji = new TypeJpEra('Meiji', '明治', new Date(1868, 1 - 1, 1), new Date(1912, 7 - 1, 29), 45);
export const Taisho = new TypeJpEra('Taisho', '大正', new Date(1912, 7 - 1, 30), new Date(1926, 12 - 1, 24), 15);
export const Showa = new TypeJpEra('Showa', '昭和', new Date(1926, 12 - 1, 25), new Date(1989, 1 - 1, 7), 64);
export const Heisei = new TypeJpEra('Heisei', '平成', new Date(1989, 1 - 1, 8), new Date(2019, 4 - 1, 30), 31);
export const Reiwa = new TypeJpEra('Reiwa', '令和', new Date(2019, 5 - 1, 1), new Date(2099, 12 - 1, 31), 64);
export function getJpEra(era: string):TypeJpEra {
  if (era === Meiji.era) return Meiji;
  if (era === Taisho.era) return Taisho;
  if (era === Showa.era) return Showa;
  if (era === Heisei.era) return Heisei;
  return Reiwa;
}
