import Address from './Address';
import Zip from './ZipCode';

/**
 * 住所
 */
export default class AddressInfo {
  address: Address;
  zip: Zip;

  constructor(
    address?: Address,
    zip?: Zip
  ) {
    this.address = (address === undefined ? new Address() : address);
    this.zip = (zip === undefined ? new Zip() : zip);
  }
  static clone(addressPerson: AddressInfo): AddressInfo {
    return new AddressInfo(addressPerson.address, addressPerson.zip);
  }
}
