
import { Vue, Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import UserIcon from '@/svg/UserIcon.vue';
import UserBaseInfo from '@/model/user/UserBaseInfo';

@Component({
  components: {
    UserIcon
  }
})
export default class RightNavigation extends Vue {
  @State('loginUser') loginUser!: UserBaseInfo;
  @Getter('isAdminGroup')isAdminGroup!: boolean;
  @Getter('isActiveUser')isActiveUser!: boolean;

  closeNavigation():void{
    this.$emit('closeNavigation');
  }
  logoutOption():void{
    // ログイン済みCookieを削除
    document.cookie = 'PERSON_LOGIN_TOKEN=false;Max-Age=0';
  }
}
