/**
 * axiosの共通設定をしてモジュール化
 */
import axiosBase from 'axios';

const axios = axiosBase.create();

// サーバー側でのタイムアウト判定に使用
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

// CSRF対策
function getHeadValue(key: string): string | null {
  const head = document.head.querySelector(`meta[name="${key}"]`);
  if (head == null) return null;
  return head.getAttribute('content');
}
const csrfHeader = getHeadValue('_csrf_header');
const csrfValue = getHeadValue('_csrf');
if (csrfHeader && csrfValue) {
  axios.defaults.headers[csrfHeader] = csrfValue;
}

// URL
const apiVerison: string = '/api/v1/';
// const apiVerison: string = '';
axios.defaults.baseURL = apiVerison;
/**
 * レスポンス処理共通化
 */
axios.interceptors.response.use(
  /** 通常時は特になし */
  (response) => response,
  /** 異常時、認証エラーならTOP画面に戻す */
  (error: any) => {
    if (error.response !== undefined &&
      error.response.status !== undefined) {
      if (error.response.status === 401) {
        // タイムアウトの場合
        // ログイン確認クッキーを削除してからリダイレクト
        document.cookie = 'PERSON_LOGIN_TOKEN=false;Max-Age=0';
        window.location.href = '/login.html?timeout';
        return Promise.reject(error);
      }
      if (error.response.status === 403) {
        if (!error.response.data) {
          // 本当はトーストで表示したいがVueコンポーネントへのアクセスが難しい
          // 通常発生しないはずなのでalertで表示
          // eslint-disable-next-line no-alert
          alert('操作する権限がない処理が実行されました。');
          return Promise.reject(error);
        }
      }
    }
    // アプリで認識している認証エラーの場合は通常のエラー処理
    return Promise.reject(error);
  }
);

export default axios;
