export default class Priv {
    value: string;
    isAdminGroup: boolean;
    readPriv: string;

    constructor(
      value?: string,
      isAdminGroup?: boolean,
      readPriv?: string
    ) {
      this.value = (value === undefined ? '' : value);
      this.isAdminGroup = (isAdminGroup === undefined ? false : isAdminGroup);
      this.readPriv = (readPriv === undefined ? '' : readPriv);
    }
    static clone(source: Priv): Priv {
      return new Priv(source.value, source.isAdminGroup, source.readPriv);
    }
}
