export default class Waistsize {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: Waistsize): Waistsize {
      return new Waistsize(source.value);
    }
}
