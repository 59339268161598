export default class Blood {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: Blood): Blood {
      return new Blood(source.value);
    }
}
