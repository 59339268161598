
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class FooterComponent extends Vue {
  // :class="{'my-footer-shorten': isShortForm}"
  // isShortForm = (document.body.clientHeight + 30) < window.innerHeight;

  // mounted(): void {
  //   window.addEventListener('resize', this.onResize);
  // }
  // beforeDestroy(): void {
  //   window.removeEventListener('resize', this.onResize);
  // }
  // onResize(): void {
  //   this.isShortForm = (document.body.clientHeight + 30) < window.innerHeight;
  // }
}
