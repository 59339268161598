export default class UserNameKana {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: UserNameKana): UserNameKana {
      return new UserNameKana(source.value);
    }
}
