export default class Status {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: Status): Status {
      return new Status(source.value);
    }
}

export const STATUS_VALUE = {
/** 仮登録(メール認証前)) */
  KARI: -1,
  /** 未承認 */
  INITIAL: 0,
  /** 在籍中 */
  AVAILABLE: 1,
  /** 離職 */
  DISABLE: 9,
  /** すべて（検索条件用） */
  ALL: 99
}as const;
