
import { Vue, Component } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import RightNavigation from '@/components/RightNavigation.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import UserApi from '@/module/api/UserApi';
import UserBaseInfo from './model/user/UserBaseInfo';

@Component({
  components: {
    RightNavigation,
    FooterComponent
  }
})
export default class App extends Vue {
  showSidepanel?:boolean = false;
  showSnackbar: boolean = false;
  SnackbarMessage: string = '';
  SnackbarisInfinity: boolean = false;
  // ユーザ情報
  @State('loginUser') loginUser!: UserBaseInfo;
  @Mutation('commitUserInfo') commitUserInfo: any;

  /**
   * ログイン済み判定
   */
  get isLogin(): boolean {
    // Cookieの有無でログイン済みを判断する（SESSIONはhttpOnlyなので参照不可のため代替Cookieで判断）
    const hoge = document.cookie?.split('; ');
    if (!hoge) return false;
    const finded = hoge.find((row) => row.startsWith('PERSON_LOGIN_TOKEN'));
    if (!finded) return false;
    return finded.length > 0;
  }

  mounted(): void {
    this.$root.$on('event-show-snackbar-infinity', (param: string) => {
      this.onEventShowSnackbarInfinity(param);
    });
    this.$root.$on('event-show-snackbar', (param: string) => {
      this.onEventShowSnackbar(param);
    });
    if (this.isLogin) {
      UserApi.getLoginUser().then((res) => {
        this.commitUserInfo(res.data);
      });
    }
  }
  /**
   * スナックバー表示(閉じる押下まで消えない)
   */
  onEventShowSnackbarInfinity(message: string): void {
    this.SnackbarMessage = message;
    this.SnackbarisInfinity = true;
    this.showSnackbar = true;
  }

  clickLogo():void{
    this.$root.$emit('clickLogo');
  }

  /**
   * スナックバー表示(自動で消える)
   */
  onEventShowSnackbar(message: string): void {
    this.SnackbarMessage = message;
    this.SnackbarisInfinity = false;
    this.showSnackbar = true;
  }
}
