export default class AddressLocation {
  lat: number;
  lng:number;

  constructor(
    lat: number,
    lng: number
  ) {
    this.lat = (lat === undefined ? 0 : lat);
    this.lng = (lng === undefined ? 0 : lng);
  }
  public static clone(location: AddressLocation): AddressLocation {
    return new AddressLocation(location.lat, location.lng);
  }
}
