export default class Email {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: Email): Email {
      return new Email(source.value);
    }
}
