export default class UserID {
    value: string;
    code: string;

    constructor(value?: string, code?: string) {
      this.value = (value === undefined ? '' : value);
      this.code = (code === undefined ? '' : code);
    }
    static clone(source: UserID): UserID {
      return new UserID(source.value, source.code);
    }
}
