import Vue from 'vue';
import Vuex from 'vuex';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import { STATUS_VALUE } from '@/model/user/Status';
import UserID from '@/model/user/UserID';

Vue.use(Vuex);

class PageCache {
  [key: string]: any;
}
export declare interface pageCommitter { pageName: string, value: any }

export default new Vuex.Store({
  state: {
    /** ユーザ情報 */
    loginUser: new UserBaseInfo(),
    pageCache: new PageCache()
  },
  mutations: {
    /** ユーザ情報 */
    commitUserInfo(state, result) {
      state.loginUser = result;
    },
    commitPageCache(state, result: pageCommitter) {
      state.pageCache[result.pageName] = result.value;
    }
  },
  getters: {
    isLoaded(state): boolean {
      return state.loginUser.userId.value !== '';
    },
    isAdminGroup(state): boolean {
      return state.loginUser.priv.isAdminGroup;
    },
    /**
     * 承認済みユーザーか判定
     */
    isActiveUser(state): boolean {
      const status = Number(state.loginUser.status.value);
      if (status === STATUS_VALUE.AVAILABLE) return true;
      return false;
    },
    pageCache(state): any {
      return (key: string) => {
        try {
          return state.pageCache[key];
        } catch {
          return undefined;
        }
      };
    },
    userId(state): UserID {
      return state.loginUser.userId;
    }
  },
  actions: {
  },
  modules: {
  },
});
