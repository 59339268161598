import UserID from './UserID';
import UserName from './UserName';
import UserNameKana from './UserNameKana';
import PhoneNumber from './PhoneNumber';
import Email from './Email';
import Status from './Status';
import Password from './Password';
import Sex from './Sex';
import Size from './Size';
import Waistsize from './Waistsize';
import Station from './Station';
import Blood from './Blood';
import TypeJpDate from '../type/TypeJpDate';
import Priv from './Priv';
import AddressInfo from '../address/AddressInfo';
import AddressLocation from './AddressLocation';

/**
 * ユーザ基本情報
 */
export default class UserBaseInfo {
    /** ユーザID */
    userId: UserID;
    /** ユーザ名称 */
    username: UserName;
    /** ユーザ名称かな */
    usernamekana: UserNameKana;
    /** 生年月日 */
    jpBirthday: TypeJpDate;
    /** 性別 */
    sex: Sex;
    /** 血液型 */
    blood: Blood;
    /** 作業服サイズ */
    size: Size;
    /** ウエストサイズ */
    waistsize: Waistsize;
    /** 自宅住所 */
    addressPerson: AddressInfo;
    /** 緯度経度 */
    addressLocation: AddressLocation;
    /** 最寄り駅 */
    station: Station;
    /** 電話番号 */
    phone: PhoneNumber;
    /** 携帯電話番号 */
    phonecell: PhoneNumber;
    /** 携帯メールアドレス */
    emailcell: Email;
    /** PCメールアドレス */
    emailpc: Email;
    /** ステータス */
    status: Status;
    /** パスワード */
    password: Password;
    /** 権限 */
    priv:Priv;

    constructor(
      userId? : UserID,
      username? : UserName,
      usernamekana? : UserNameKana,
      phone? : PhoneNumber,
      jpBirthday? : TypeJpDate,
      sex? : Sex,
      blood? : Blood,
      size? : Size,
      waistsize? : Waistsize,
      addressPerson? : AddressInfo,
      addressLocation?: AddressLocation,
      station? : Station,
      phonecell? : PhoneNumber,
      emailcell? : Email,
      emailpc? : Email,
      status? : Status,
      password? : Password,
      priv? : Priv
    ) {
      this.userId = (userId === undefined ? new UserID() : userId);
      this.username = (username === undefined ? new UserName() : username);
      this.usernamekana = (usernamekana === undefined ? new UserNameKana() : usernamekana);
      this.jpBirthday = (jpBirthday === undefined ? new TypeJpDate('Heisei', 1, 1, 1) : jpBirthday);
      this.sex = (sex === undefined ? new Sex('男性') : sex);
      this.blood = (blood === undefined ? new Blood('Ａ型') : blood);
      this.size = (size === undefined ? new Size('ＸＳ') : size);
      this.waistsize = (waistsize === undefined ? new Waistsize() : waistsize);
      this.addressPerson = (addressPerson === undefined ? new AddressInfo() : addressPerson);
      this.addressLocation = (addressLocation === undefined ? new AddressLocation(0, 0) : addressLocation);
      this.station = (station === undefined ? new Station() : station);
      this.phone = (phone === undefined ? new PhoneNumber() : phone);
      this.phonecell = (phonecell === undefined ? new PhoneNumber() : phonecell);
      this.emailcell = (emailcell === undefined ? new Email() : emailcell);
      this.emailpc = (emailpc === undefined ? new Email() : emailpc);
      this.status = (status === undefined ? new Status() : status);
      this.password = (password === undefined ? new Password() : password);
      this.priv = (priv === undefined ? new Priv() : priv);
    }

    public static cloneList(source: UserBaseInfo[]): UserBaseInfo[] {
      const result: UserBaseInfo[] = [];
      source.forEach((s) => {
        result.push(UserBaseInfo.clone(s));
      });

      return result;
    }

    public static clone(source: UserBaseInfo): UserBaseInfo {
      const result = new UserBaseInfo();
      result.userId = UserID.clone(source.userId);
      result.username = UserName.clone(source.username);
      result.usernamekana = UserNameKana.clone(source.usernamekana);
      result.jpBirthday = TypeJpDate.clone(source.jpBirthday);
      result.sex = Sex.clone(source.sex);
      result.blood = Blood.clone(source.blood);
      result.size = Size.clone(source.size);
      result.waistsize = Waistsize.clone(source.waistsize);
      result.addressPerson = AddressInfo.clone(source.addressPerson);
      result.addressLocation = AddressLocation.clone(source.addressLocation);
      result.station = Station.clone(source.station);
      result.phone = PhoneNumber.clone(source.phone);
      result.phonecell = PhoneNumber.clone(source.phonecell);
      result.emailcell = Email.clone(source.emailcell);
      result.emailpc = Email.clone(source.emailpc);
      result.status = Status.clone(source.status);
      result.priv = Priv.clone(source.priv);
      return result;
    }

    public isMan():boolean {
      if (this.sex.value === '男性') {
        return true;
      }
      return false;
    }

    public isWoman():boolean {
      if (this.sex.value === '女性') {
        return true;
      }
      return false;
    }

    public toDisplayZip():string {
      return `${this.addressPerson.zip.value.substring(0, 3)}-${this.addressPerson.zip.value.substring(3)}`;
    }

    public toDisplayPhone():string {
      return `${this.phone.value.substring(0, 3)}-${this.phone.value.substring(3, 7)}-${this.phone.value.substring(7)}`;
    }

    public toDisplayPhoneCell():string {
      return `${this.phonecell.value.substring(0, 3)}-${this.phonecell.value.substring(3, 7)}-${this.phonecell.value.substring(7)}`;
    }
}
