import Vue from 'vue';
import VueMaterial from 'vue-material';
import App from './App.vue';
import router from './router';
import store from './store';
// import './registerServiceWorker';
import 'vue-material/dist/vue-material.min.css';
// import 'vue-material/dist/theme/default.css';
// eslint-disable-next-line import/order
import FocusInsert from '@/directives/focusinsert';

Vue.use(VueMaterial);
Vue.directive('focusinsert', FocusInsert);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
