import Moment from 'moment';
import TypeJpEra, { getJpEra } from './TypeJpEra';

export default class TypeJpDate {
  era: string;
  year: number;
  month: number;
  day: number;
  jpDateYearMonth: string;
  jpDateYearMonthDay: string;
  age: string

  constructor(
    era?: string,
    year?: number,
    month?: number,
    day?: number,
    jpDateYearMonth?: string,
    jpDateYearMonthDay?: string,
    age?: string
  ) {
    this.era = (era === undefined ? 'Reiwa' : era);
    this.year = (year === undefined ? 1 : year);
    this.month = (month === undefined ? 1 : month);
    this.day = (day === undefined ? 1 : day);
    this.jpDateYearMonth = (jpDateYearMonth === undefined ? '' : jpDateYearMonth);
    this.jpDateYearMonthDay = (jpDateYearMonthDay === undefined ? '' : jpDateYearMonthDay);
    this.age = (age === undefined ? '' : age);
  }

  maxYears(): number {
    return getJpEra(this.era).maxYears;
  }

  get isValid(): boolean {
    if (this.era === '') return false;
    const typeEra = getJpEra(this.era);
    const value = new Date(typeEra.min.getFullYear() + Number(this.year) - 1, this.month - 1, this.day);
    if (value === undefined) return false;
    if (value < typeEra.min) return false;
    if (value > typeEra.max) return false;
    return this.validDate();
  }

  isEmpty(): boolean {
    if (this.era !== '') return false;
    if (this.year > 0) return false;
    if (this.month > 0) return false;
    if (this.day > 0) return false;
    return true;
  }

  /**
   * 日付として正しいかチェック
   */
  private validDate() {
    const typeEra = getJpEra(this.era);
    const dateStr = `${typeEra.min.getFullYear() + Number(this.year) - 1}-${this.month}-${this.day}`;
    return Moment(dateStr, 'YYYY-M-D', true).isValid();
  }

  toString(): string {
    return `${getJpEra(this.era).jpEra + (`0${this.year}`).slice(-2)}年${(`0${this.month}`).slice(-2)}月${(`0${this.day}`).slice(-2)}日`;
  }

  toDate(): Date {
    const typeEra = getJpEra(this.era);
    return new Date(typeEra.min.getFullYear() + Number(this.year) - 1, this.month - 1, this.day);
  }
  toDateString(): string {
    const typeEra = getJpEra(this.era);
    return `${typeEra.min.getFullYear() + Number(this.year) - 1}-${this.month}-${this.day}`;
  }

  public static getToday(): TypeJpDate {
    const reiwa = getJpEra('Reiwa');
    const now = new Date();
    return new TypeJpDate(reiwa.era, now.getFullYear() - reiwa.min.getFullYear() + 1, now.getMonth() + 1, now.getDate());
  }

  public static getTodayMonth(): TypeJpDate {
    const reiwa = getJpEra('Reiwa');
    const now = new Date();
    return new TypeJpDate(reiwa.era, now.getFullYear() - reiwa.min.getFullYear() + 1, now.getMonth() + 1, 1);
  }

  public static empty(): TypeJpDate {
    return new TypeJpDate('', 0, 0, 0, '', '', '');
  }

  public static clone(source: TypeJpDate): TypeJpDate {
    const result = new TypeJpDate();
    result.era = source.era;
    result.year = source.year;
    result.month = source.month;
    result.day = source.day;

    result.jpDateYearMonth = source.jpDateYearMonth;
    result.jpDateYearMonthDay = source.jpDateYearMonthDay;
    result.age = source.age;

    return result;
  }
}
