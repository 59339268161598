export default class Station {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: Station): Station {
      return new Station(source.value);
    }
}
