export default class PhoneNumber {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: PhoneNumber): PhoneNumber {
      return new PhoneNumber(source.value);
    }
}
