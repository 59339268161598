export default class UserName {
    value: string;

    constructor(value?: string) {
      this.value = (value === undefined ? '' : value);
    }
    static clone(source: UserName): UserName {
      return new UserName(source.value);
    }
}
